import React, { useRef } from 'react';
import Lottie from "lottie-react";

const ResetPassword = ({ redirectLink }) => {
  const animation = useRef(null);

  return (
    <div style={{
      flex: 1,
      color: '#303032',
      textAlign: 'center',
      backgroundColor: 'rgb(194, 216, 76)',
      padding: '5%',
      boxSizing: 'border-box', // Ensure padding is included in total width/height
    }}>
      <div style={{
        fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
        marginBottom: '2rem',
      }}>
        <h1 style={{
          textAlign: 'center',
          fontFamily: 'beezle',
          fontSize: '1.8rem', // Adjust for responsiveness
        }}>
          PASSWORD RESET FOR NEW DEW
        </h1>
      </div>

      <div style={{
        fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
        backgroundColor: '#efede7',
        margin: 'auto',
        width: '100%',
        maxWidth: '600px', // Restrict max width for larger screens
        padding: '3rem 2rem',
        borderRadius: '9px',
        boxSizing: 'border-box',
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Lottie
            animationData={require("../../assets/eve1.json")}
            style={{
              width: '100%',
              maxWidth: '300px', // Limit width for responsiveness
              height: 'auto',
              marginBottom: '2rem',
            }}
            autoPlay
          />
          <h1 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Reset Password</h1>
        </div>

        <div style={{
          fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
          backgroundColor: '#ddd',
          padding: '2rem',
          marginTop: '1.5rem',
          borderRadius: '8px',
          boxSizing: 'border-box',
        }}>
          <p style={{ fontSize: '1rem' }}>
            Please follow this link to{' '}
            <a href={redirectLink} style={{ cursor: 'pointer' }}>
              <button style={{
                border: '2px solid black',
                backgroundColor: 'white',
                color: 'black',
                padding: '10px 20px',
                fontSize: '1rem',
                cursor: 'pointer',
                borderRadius: '5px',
              }}>
                Reset
              </button>
            </a>{' '}
            your password.
          </p>
          <small style={{ display: 'block', marginTop: '1rem', color: '#555' }}>
            This link expires in 60 minutes.
          </small>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
